<template>
    <div class="wrapper_cover">
        <thin-navbar/>
        <div class="d-flex">
            <b-container class="m-auto text-center" :fluid="fluidContainer">
                <slot/>
            </b-container>
        </div>
        <footer>
            <router-link :to="{name: 'cgu'}">
                Conditions générales d'utilisation et mentions légales
            </router-link>
        </footer>
    </div>
</template>

<script>
    const ThinNavbar = () => import('@/components/layout/ThinNavbar');

    export default {
        name: "CoverPageTemplate",
        components: {ThinNavbar},
        props: {
            fluidContainer: {
                type: Boolean,
                default: false,
                description: "Whether the main container at the center should be fluid or not"
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/css/_thin_white_links";
</style>

<style scoped>
    div.wrapper_cover {
        min-height: 100vh;
        /* Linear gradient darkens the image */
        background: linear-gradient(rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.66)), url("/img/background.jpg") no-repeat center;
        background-attachment: fixed;
        background-size: cover;
    }

    div.d-flex {
        min-height: calc(100vh - 32px);
    }

    .container {
        padding-top: 80px; /* Enough for a 3 lines navbar on small screens */
        padding-bottom: 64px;
    }
</style>
